import React from "react"
import Layout, { Section } from "../../components/Layout"

export default () => (
  <Layout>
    <Section>
      <div className="container">
        <div className="content">
          <h1>Agradecemos a mensagem!</h1>
          <p>Em breve entraremos em contato.</p>
        </div>
      </div>
    </Section>
  </Layout>
)
